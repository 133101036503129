import React, { useState, useEffect, useRef } from "react";
import {
    shuffle,
    jumpOut,
    drawGridBoard,
} from '../lib/canvas.js';
import {
    PLAYER_1,
    PLAYER_2,
    initBoard,
    getBestMove,
    getBestMoveEmu,
    updateCells,
    checkWinner,
    STATUS_INIT,
    STATUS_CHECK,
    STATUS_PROCESS,
    STATUS_BOT,
    STATUS_WAITING, getXconfig, getOconfig,
} from '../lib/gomoku.js';
import './index.css';


const Game = () => {
    const [boxPosition, setBoxPosition] = useState({ left: 0, top: 0, right: null, bottom: null });
    const [status, setStatus] = useState(STATUS_WAITING);
    const [selected, setSelected] = useState(PLAYER_1);
    const [currentRole, setCurrentRole]  = useState(PLAYER_1);

    const canvasRef = useRef(null);
    const cells = useRef(initBoard());
    const player = useRef(PLAYER_1);
    const game= useRef(false);
    const winner = useRef(null);
    const round = useRef(0);
    const history = useRef([]);
    const board = useRef({});

    const nextTurn = (x, y) => {
        // console.log('nextTurn');
        if (game.current) {
            history.current.push({row: x, col: y});
            if (!board.current[x]) board.current[x] = {};
            if (!board.current[x][y]) {
                board.current[x][y] = {...player.current === PLAYER_1 ? getXconfig() : getOconfig()};
                board.current[x][y].player = player.current;
            }
            // console.log(JSON.stringify(board.current, null,1));
            cells.current = updateCells(x, y, cells.current, player.current);
        }
    }
    const runBot = async () => {
        // console.log('getNext board.current', board.current, Object.keys(board.current));
        // const lines = Object.keys(board.current);
        // const logic = lines.map(x => {
        //     console.log('getNext board[x]', board.current[x], Object.keys(board.current[x]));
        //     const cells = board.current[x] ? Object.keys(board.current[x]) : [];
        //     return cells.map(y => board.current[x][y].player ? board.current[x][y].player : null);
        // });
        // console.log('runBot');
        const response = await getBestMove(
                    cells.current.map(line=>line.map(cell => cell.player ? cell.player : null)),
                    player.current,
                    player.current === PLAYER_1 ? PLAYER_2 : PLAYER_1,
                );
        // const response = await getBestMoveEmu(
        //     cells.current,
        //     history.current[history.current.length - 1],
        // );
        // console.log({response});
        if (!response.row || !response.col) {
            response.row = 5;
            response.col = 5;
        }
       nextTurn(response.row, response.col);
    }

    useEffect(() => {
        // console.log('Once & draw');
        drawGridBoard(canvasRef.current);
    }, []);

    useEffect(() => {
        // console.log('useEffect player.current', player.current);
        if (status === STATUS_INIT) {
            // console.log('STATUS_INIT');
            game.current = true;
            winner.current = null;
            round.current = 1;
            history.current.length = 0;
            board.current = {};
            cells.current = initBoard();
            player.current = PLAYER_1;
            drawGridBoard(canvasRef.current);
            if (currentRole === PLAYER_2) {
                nextTurn(24, 24);
                setStatus(STATUS_PROCESS);
            } else {
                setStatus(STATUS_WAITING);
            }
        }
        if (status === STATUS_CHECK) {
            // console.log('STATUS_CHECK');
            const {row: x, col: y} = history.current[history.current.length -1];
            const result = x&&y ? checkWinner(board.current, x, y, player.current) : null;
            winner.current = result;
            if (result) {
                // console.log('FINI');
                game.current = false;
                player.current = PLAYER_1;
                drawGridBoard(canvasRef.current, board.current, winner.current);
                setStatus(STATUS_WAITING);
            } else {
                round.current++;
                setStatus(STATUS_PROCESS);
            }
        }
        if (status === STATUS_WAITING) {
            // console.log('STATUS_WAITING');
        }
        if (status === STATUS_BOT) {
            // console.log('STATUS_BOT');
            setTimeout(()=>{
                runBot()
                    .then(()=> {
                        setStatus(STATUS_CHECK);
                    });
            },100);

        }
        if (status === STATUS_PROCESS) {
            // console.log('STATUS_PROCESS', player.current, currentRole, cells.current[x][y]);
            drawGridBoard(canvasRef.current, cells.current);
            player.current = player.current === PLAYER_1 ? PLAYER_2 : PLAYER_1;
            setStatus((player.current !== currentRole) ? STATUS_BOT : STATUS_WAITING);
        }
    }, [status, board.current]);

    const handleClick = (e) => {
        // console.log('handleClick');
        if (game.current && status === STATUS_WAITING) {
            // console.log('game.current', game.current);
            const rect = canvasRef.current.getBoundingClientRect();
            const x = Math.floor((e.clientX - rect.left - 10) / 20);
            const y = Math.floor((e.clientY - rect.top - 10) / 20);
            // const clickedCell = cells.current[x] && cells.current[x][y];
            // if (!clickedCell || clickedCell.player) {
            //     return;
            // }

            if (board.current[x] && board.current[x][y] && board[x][y].player) {
                return;
            }
            nextTurn(x, y);
            setStatus(STATUS_CHECK);
        }
    };

    const handleStartGameClick = () => {
        // console.log('handleStartGameClick currentPlayer currentRole', player.current, {currentRole} );
        setStatus(STATUS_INIT);
    }

    const handleMouseMove = (e) => {
        if (game.current) {
            const boxRect = e.target.getBoundingClientRect();
            const mouseX = e.clientX - boxRect.left;
            const mouseY = e.clientY - boxRect.top;
            const box = document.getElementById("panel").getBoundingClientRect();
            if (jumpOut(mouseX, mouseY, boxRect, box, boxPosition)) setBoxPosition(current => shuffle(current));
        }
    }

    const handleChange = (event) => {
        setSelected(event.target.value);
    };

    const handleRadioClick = (player) => {
        setCurrentRole(player);
    }

    return (<div className="container" onMouseMove={handleMouseMove}>
                <canvas
                    ref={canvasRef}
                    width={1000}
                    height={1000}
                    onClick={handleClick}
                />
                <div
                    id="panel"
                    className="panel"
                    style={{
                        left: boxPosition.left,
                        top: boxPosition.top,
                        right: boxPosition.right,
                        bottom: boxPosition.bottom,
                    }}
                >
                    <h1>5x5 Battle</h1>
                    {winner.current &&
                        <h2><span>Winner: </span>
                            <span className={winner.current.player === 1 ? 'X' : 'O'}>
                                {winner.current.player === 1 ? 'X' : '0'}
                            </span>
                        </h2>}
                    {game.current && <>
                        <h2>Current Player</h2>
                        <h2>
                            <span className={player.current === 1 ? 'X' : 'O'}>
                                {player.current === 1 ? 'X' : '0'}
                            </span>
                        </h2>
                        <p>Round: {Math.floor(round.current / 2)}</p>
                    </>}
                    <div className="selectors">
                        {!game.current && (<>
                            <div className="selector0">Select side:</div>
                            <div className="selector1">
                                <input
                                    type="radio"
                                    id={PLAYER_1}
                                    name="operator"
                                    value={PLAYER_1}
                                    checked={currentRole === PLAYER_1}
                                    onChange={handleChange}
                                    onClick={() => handleRadioClick(PLAYER_1)}
                                />
                                <label htmlFor={PLAYER_1}>X</label>
                            </div>
                            <div className="selector2">
                                <input
                                    type="radio"
                                    id={PLAYER_2}
                                    name="operator"
                                    value={PLAYER_2}
                                    checked={currentRole === PLAYER_2}
                                    onChange={handleChange}
                                    onClick={() => handleRadioClick(PLAYER_2)}
                                />
                                <label htmlFor={PLAYER_2}>0</label>
                            </div>
                        </>)}
                    </div>
                        {!game.current && <div className="selector3">
                            <button
                                className="game-buttom"
                                onClick={() => handleStartGameClick()}
                                disabled={game.current}
                            >
                                Start Game
                            </button>
                        </div>}

                </div>
        </div>);
};

export default Game;